import { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Select } from '@medifind/shared-basic-components';
import { CheckBoxGroup } from '@medifind/shared-basic-components';
import { areFilterStatesEqual, useLocationStore } from '@medifind/zustand';
import { Filter } from './Filter';
import styles from './FilterShared.module.scss';

const itemsMiles = [
  { display: '1 mile', value: 1, fidelity: 3 },
  { display: '5 miles', value: 5, fidelity: 3 },
  { display: '10 miles', value: 10, fidelity: 3 },
  { display: '25 miles', value: 25, fidelity: 3 },
  { display: '50 miles', value: 50, fidelity: 3 },
  { display: '100 miles', value: 100, fidelity: 3 },
  { display: '200 miles', value: 200, fidelity: 3 },
  { display: 'State Wide', value: 'state', fidelity: 4 },
  { display: 'National', value: 'national', fidelity: 5 },
  { display: 'Global', value: 'global', fidelity: 6 },
];
const itemsKM = [
  { display: '2 km', value: 2, fidelity: 3 },
  { display: '5 km', value: 5, fidelity: 3 },
  { display: '10 km', value: 10, fidelity: 3 },
  { display: '25 km', value: 25, fidelity: 3 }, // default when fidelity 3
  { display: '50 km', value: 50, fidelity: 3 },
  { display: '100 km', value: 100, fidelity: 3 },
  { display: '200 km', value: 200, fidelity: 3 },
  { display: 'Province Wide', value: 'state', fidelity: 4 }, // default when fidelity 4
  { display: 'National', value: 'national', fidelity: 5 }, // default when fidelity 5
  { display: 'Global', value: 'global', fidelity: 6 }, // default when fidelity 6
];

// map with isSuggested: true,
// export to right place maybye ussSearch results?

export const lookupRadiusForFidelity = (location, defaultValue = 1) => {
  // Check to see to use Metric or Standard
  const { fidelity } = location;
  const items = location.milesOrKm === 'km' ? itemsKM : itemsMiles;
  const matchingItems = items.filter((d) => d.fidelity === fidelity);
  if (matchingItems.length === 0) return 'global';
  else if (matchingItems.length > 1) return matchingItems[defaultValue].value;
  else return matchingItems[0].value;
};

const getSelectedItem = ({ items = [], filter = {} }) => {
  const { selected, isSuggested } = filter;
  const item = items.find((item) => item.value === selected?.value) ?? null;
  if (item) {
    return { ...item, display: !isSuggested ? item.display : 'Recommended' };
  }
  if (items?.length === 1) {
    return items[0];
  }
  if (isSuggested) {
    return { display: 'Recommended' };
  }
  return {};
};

const RadiusFilter = ({
  filter,
  onRadiusChange,
  onClear,
  classes = {},
  what,
  label = 'Distance',
  smallAt,
  maxFidelity = 6,
  noHeader,
  style,
  isRadio = false,
  hideReset = false,
}) => {
  const { selected, getInitialState, setFilterValue, reset, canSuggest } = filter;
  const location = useLocationStore();
  const firstRender = useRef(true);

  const items = useMemo(() => {
    const itemsRegional = location.milesOrKm === 'km' ? itemsKM : itemsMiles;
    // filter out items that are less than location's fidelity (default 6 - global)
    return itemsRegional
      .filter((t) => t.fidelity >= Math.min(location.fidelity || 6, maxFidelity) && t.fidelity <= maxFidelity)
      .filter((t) => t.fidelity !== 4 || (t.fidelity === 4 && location.stateProvinceCode));
  }, [location]);

  const handleRadiusChange = (item) => {
    setFilterValue && setFilterValue(item);
    onRadiusChange && onRadiusChange(item, true);
  };

  const selectedItem = getSelectedItem({ items, filter });

  useEffect(() => {
    if (!canSuggest && !firstRender.current) {
      reset && reset();
    }
    if (firstRender.current) firstRender.current = false;
  }, [items, location, canSuggest]);

  useEffect(() => {
    // Update the filter if the selected value is less than the location fidelity
    // Ignore for recommended filters
    if (selected && selected.fidelity < Math.min(location?.fidelity || 6, maxFidelity)) {
      setFilterValue(items[0]);
    }
  }, [items, selected, location]);

  return (
    <Filter
      classes={classes}
      label={label}
      what={what}
      smallAt={smallAt}
      placeholder={selectedItem?.display}
      noHeader={noHeader}
      style={style}
      onClear={() => {
        reset && reset();
        onClear && onClear();
      }}
      hideReset={hideReset}
      isChanged={!filter.isSuggested && !areFilterStatesEqual(getInitialState(), filter)}
    >
      {isRadio ? (
        <CheckBoxGroup
          selectedValues={[!filter.isSuggested ? selectedItem : {}]}
          labelId={'-'} // Is replaced by <Filter>
          items={items}
          type="radio"
          onChange={handleRadiusChange}
        />
      ) : (
        <Select
          id="radius-filter"
          label
          hideLabel
          onChange={handleRadiusChange}
          items={items}
          selectValue={selectedItem}
          classes={{
            ...classes,
            menuWrapper: classNames(classes.menuWrapper, styles['filter-menu-wrapper']),
            inputWrapper: styles['filter-input-wrapper'],
          }}
          style={style}
        />
      )}
    </Filter>
  );
};

RadiusFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  onRadiusChange: PropTypes.func,
  what: PropTypes.node,
  classes: PropTypes.object,
  noHeader: PropTypes.bool,
  label: PropTypes.string,
  smallAt: PropTypes.string,
  maxFidelity: PropTypes.number, // The max level of items to be shown in the drop down. Ex. maxFidelity=5 will hide global
  isRadio: PropTypes.bool,
  hideReset: PropTypes.bool,
  onClear: PropTypes.func,
};

export { RadiusFilter as default, RadiusFilter };
