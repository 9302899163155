import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useSelect } from 'downshift';
import PropTypes from 'prop-types';
import styles from './Autocomplete-Select.module.scss';

const Select = ({
  id,
  label,
  labelId,
  hideLabel,
  placeholder = 'Please select one',
  onChange,
  items = [],
  classes = {},
  selectValue,
  initialValue,
  fullWidth = true,
  className,
  iconBefore,
  iconOnlyAt,
  disabled,
  showWrapper = true,
  fillInput = true,
  isMenuListScroll = true,
  style = {},
  childrenPostMenuWrapper = () => {},
  autocomplete = 'off',
  preventSelection,
  isControlledInput: _isControlledInput,
}) => {
  const dedup = useRef();
  const [isControlledInput] = useState(selectValue != null || _isControlledInput);
  const [selectedItem, setSelectedItem] = useState(selectValue || initialValue);
  useEffect(() => {
    if (isControlledInput) {
      setSelectedItem(selectValue);
      dedup.current = null;
    }
  }, [selectValue?.value]);
  const itemIndex = items?.findIndex((item) => item.value === selectedItem?.value);
  const args = useSelect({
    initialSelectedItem: initialValue,
    initialInputValue: initialValue?.display,
    ...(isControlledInput && {
      selectedItem: selectedItem ?? { display: '', value: '' },
      defaultHighlightedIndex: itemIndex === -1 ? 0 : itemIndex,
    }),
    items,
    labelId: labelId,
    itemToString: (item) => (item ? item.display : ''),
    // Todo: There is an error here, onChange is not working, onInputValueChange works as a replacement but it causes a change when the initial value is loaded.
    // onSelectedItemChange does fix some issues with endless changes but when try in mobile mode in dev tools it causes an endless loop on change, (touch related?)
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem !== dedup.current) {
        if (preventSelection) {
          args.reset();
        } else {
          dedup.current = selectedItem;
          setSelectedItem(selectedItem);
        }
        onChange(selectedItem);
      }
    },

    stateReducer: (state, { type, props, changes }) => {
      switch (type) {
        case useSelect.stateChangeTypes.InputKeyDownEnter:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...state,
            ...changes,
          };
        case useSelect.stateChangeTypes.keyDownEscape: // don't clear input & don't clear selectedItem
          return {
            ...changes,
            selectedItem: state.selectedItem,
          };
        default:
          return { ...state, ...changes };
      }
    },
  });
  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps, getLabelProps } = args;

  return (
    <div className={classNames(styles['shared-select'], className, classes.selectWrapper)} {...(id ? { id: id + '-container' } : {})}>
      {labelId ? (
        getLabelProps({ id: labelId, htmlFor: id }) && undefined
      ) : (
        <label
          style={{ ...style.label, ...(hideLabel ? { display: 'none' } : {}) }}
          {...getLabelProps({ htmlFor: id })}
          className={classNames(styles['label'], classes.label)}
        >
          {label}
        </label>
      )}
      <div
        style={style.menuWrapper}
        className={classNames(
          styles['menu-wrapper'],
          { [styles['menu-wrapper--wrapping']]: showWrapper, [styles['menu-wrapper--fill-input']]: fillInput },
          classes.menuWrapper,
          {
            [classNames(styles['open'], classes.open)]: isOpen && items.length,
          },
        )}
      >
        <div
          className={classNames(styles['autocomplete__input-wrapper'], classes.inputWrapper, {
            [classNames(styles['open'], classes.open)]: isOpen,
          })}
        >
          <button
            type="button"
            style={style.input}
            disabled={disabled}
            className={classNames(styles['select-toggle'], classes.select, classes.input, {
              [classNames(styles['selected'], classes.selected)]: selectedItem?.value,
              [styles['full-width']]: fullWidth,
              [classNames(styles['open'], classes.open)]: isOpen && items.length,
            })}
            {...getToggleButtonProps({
              ...(id ? { id } : {}),
              tabIndex: '0',
            })}
          >
            {iconBefore && (
              <span
                style={style.iconBefore}
                className={classNames(styles['icon--before'], { [styles['icon']]: !!label }, classes.iconBefore)}
              >
                {iconBefore}
              </span>
            )}
            {/* put a skeleton here w/ no value*/}
            <span
              className={classNames(styles['button-label'], classes.buttonLabel, {
                [`hide--${iconOnlyAt}`]: !!iconOnlyAt,
              })}
            >
              {selectedItem?.display ?? placeholder}
            </span>
          </button>
        </div>
        <div
          style={style.menu}
          className={classNames(styles['menu'], classes.menu, {
            [classNames(styles['open'], classes.open)]: isOpen && items.length,
          })}
        >
          <ul
            style={style.menuList}
            className={classNames(styles['menu-list'], classes.menuList, isMenuListScroll && styles['menu-scroll-container'], {
              [classNames(styles['open'], classes.open)]: isOpen && items.length,
            })}
            {...getMenuProps({ ...(id ? { id: `${id}-menu` } : {}), tabIndex: '0' })}
          >
            {isOpen &&
              items.map((item, index) => (
                // eslint-disable-next-line react/jsx-key
                <li
                  style={style.menuItem}
                  className={classNames(
                    styles['menu-item'],
                    styles[item?.isDisabled ? 'disabled-option' : 'clickable-menu-item'],
                    classes.menuItem,
                    item.className,
                    {
                      [classNames(styles['highlighted'], classes['highlighted'])]: highlightedIndex === index,
                    },
                  )}
                  {...getItemProps({
                    index,
                    item,
                    hidden: item.hidden,
                    ...(id ? { id: `${id}-item-${index}` } : {}),
                  })}
                  key={index}
                >
                  {item.display}
                </li>
              ))}
          </ul>
        </div>
      </div>
      {childrenPostMenuWrapper(args)}
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  label: function (props, propName, componentName) {
    if (!props.labelId && !props.label) {
      return new Error(`One of 'label' or 'labelId' is required by '${componentName}' component.`);
    }
    if (props.labelId) {
      PropTypes.checkPropTypes(
        {
          labelId: PropTypes.string, // or any other PropTypes you want
        },
        props,
        propName,
        componentName,
      );
    }
    return null;
  },
  labelId: PropTypes.string,
  hideLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  selectValue: PropTypes.object,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    display: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    value: PropTypes.any,
    className: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      value: PropTypes.any,
      className: PropTypes.string,
    }),
  ).isRequired,
  style: PropTypes.object,
  classes: PropTypes.shape({
    menuWrapper: PropTypes.string,
    open: PropTypes.string,
    select: PropTypes.string,
    menu: PropTypes.string,
    menuList: PropTypes.string,
    menuItem: PropTypes.string,
    highlighted: PropTypes.string,
    buttonLabel: PropTypes.string,
  }),
  className: PropTypes.string,
  autocomplete: PropTypes.string,
  showWrapper: PropTypes.bool,
  isControlledInput: PropTypes.bool,
  fillInput: PropTypes.bool,
  isMenuListScroll: PropTypes.bool,
  disabled: PropTypes.bool,
  childrenPostMenuWrapper: PropTypes.func,
  preventSelection: PropTypes.bool,
};

export { Select as default, Select };
