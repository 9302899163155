import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { migrateReduxSessionStorageFilterValue, sessionStorageMF } from '../../utils';
import { setFilterValue } from '../filterValue';

const initialState = {
  display: 'Types',
  name: 'infusionCenterTypes',
  type: 'singleSelect',
  selected: { display: 'Any', value: null },
  options: [
    { display: 'Any', value: null },
    { display: 'Freestanding Infusion Center', value: 'Freestanding Infusion Center' },
    { display: 'Home Infusion Service', value: 'Home Infusion Service' },
    { display: 'Hospital Outpatient Department', value: 'Hospital Outpatient Department' },
    { display: `Physician's Office`, value: `Physicians Office` },
  ],
};

const storeName = 'infusionCenterTypes';
export const useInfusionCenterTypesFilter = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
        setFilterValue: (v) => updateInfusionCenterTypesOption(v),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => sessionStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxSessionStorageFilterValue({ state, key: 'infusionCenterTypes' });
        },
      },
    ),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const updateInfusionCenterTypesOption = (option) => {
  setFilterValue(useInfusionCenterTypesFilter, option);
};

export const clearInfusionCenterTypesOption = () =>
  useInfusionCenterTypesFilter.setState({ ...initialState }, false, {
    type: 'clearInfusionCenterTypesOption',
  });
